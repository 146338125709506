import React from 'react'
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { PageProps } from 'gatsby'
import { useIntl } from "gatsby-plugin-intl"

type ImpressumQueryProps = PageQuery<{
  title: string;
  body: MarkdownField;
}>

const ImpressumPage = ({ data }: PageProps<ImpressumQueryProps>) => {
  const { locale } = useIntl()
  const { markdownRemark } = data
  const frontmatter = markdownRemark.frontmatter[locale]!

  return (
    <Layout seo={{title: frontmatter.title, description: ''}}>
      <h1 style={{textAlign: 'center'}}>{frontmatter.title}</h1>
      <div dangerouslySetInnerHTML={{__html: frontmatter.body.html}}/>
    </Layout>
  )
}

export const pageQuery = graphql`
  fragment ImpressumFragment on FrontmatterLocalized {
    title
    body {
      html
    }
  }

  query ImpressumPage {
    markdownRemark(fileAbsolutePath: {regex: "/pages\/impressum.md/"}) {
      frontmatter {
        de {
          ...ImpressumFragment
        }
        en {
          ...ImpressumFragment
        }
      }
    }
  }
`

export default ImpressumPage
